import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Link, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import ReceiptIcon from '@material-ui/icons/Receipt';
// import useAuth from '../../hooks/useAuth';
// import BriefcaseIcon from '../../icons/Briefcase';
// import CalendarIcon from '../../icons/Calendar';
// import ChartPieIcon from '../../icons/ChartPie';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
// import ChatAltIcon from '../../icons/ChatAlt';
// import ClipboardListIcon from '../../icons/ClipboardList';
// import FolderOpenIcon from '../../icons/FolderOpen';
// import MailIcon from '../../icons/Mail';
// import ShareIcon from '../../icons/Share';
// import ShoppingBagIcon from '../../icons/ShoppingBag';
// import ShoppingCartIcon from '../../icons/ShoppingCart';
// import UserIcon from '../../icons/User';
import UsersIcon from '../../icons/Users';
// import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import logo from '../../assets/images/Kocars/original.png';
import getInitials from '../../utils/getInitials';

import CarRental from '@material-ui/icons/CarRental';
import EmojiTransportation from '@material-ui/icons/EmojiTransportation';
import EventNote from '@material-ui/icons/EventNote';
import CarRepair from '@material-ui/icons/CarRepair';
import Assignment from '@material-ui/icons/Assignment';
import Sell from '@material-ui/icons/Sell';

let sections = [];

// const sections = [
//   {
//     title: 'General',
//     items: [
//       {
//         title: 'Resumen',
//         path: '/dashboard',
//         icon: <ChartSquareBarIcon fontSize="small" />
//       },
//       // {
//       //   title: 'Analytics',
//       //   path: '/dashboard/analytics',
//       //   icon: <ChartPieIcon fontSize="small" />
//       // },
//       // {
//       //   title: 'Finance',
//       //   path: '/dashboard/finance',
//       //   icon: <ShoppingBagIcon fontSize="small" />
//       // },
//       // {
//       //   title: 'Account',
//       //   path: '/dashboard/account',
//       //   icon: <UserIcon fontSize="small" />
//       // }
//     ]
//   },
//   {
//     title: 'Concesionario',
//     items: [
//       {
//         title: 'Vehiculos',
//         path: '/dashboard/concesionario/vehiculos',
//         icon: <CarRental fontSize="small" />
//       },
//       {
//         title: 'Sucursales',
//         path: '/dashboard/concesionario/sucursales',
//         icon: <EmojiTransportation fontSize="small" />
//       },
//       {
//         title: 'Citas',
//         path: '/dashboard/concesionario/citas',
//         icon: <EventNote fontSize="small" />
//       },
//     ]
//   },
//   {
//     title: 'Taller',
//     items: [
//       {
//         title: 'Clientes',
//         path: '/dashboard/taller',
//         icon: <UsersIcon fontSize="small" />,
//         children: [
//           {
//             title: 'Nuevo Cliente',
//             path: '/dashboard/taller/clientes/crear'
//           },
//           {
//             title: 'Ver Clientes',
//             path: '/dashboard/taller/clientes'
//           }
//         ]
//       },
//       {
//         title: 'Vehículos',
//         path: '/dashboard/taller',
//         icon: <CarRepair fontSize="small" />,
//         children: [
//           {
//             title: 'Nuevo Vehículo',
//             path: '/dashboard/taller/vehiculos/crear'
//           },
//           {
//             title: 'Ver Vehículos',
//             path: '/dashboard/taller/vehiculos'
//           }
//         ]
//       },
//       {
//         title: 'Ordenes',
//         icon: <Assignment fontSize="small" />,
//         path: '/dashboard/taller',
//         children: [
//           {
//             title: 'Nueva Orden',
//             path: '/dashboard/taller/ordenes/crear'
//           },
//           {
//             title: 'Ver Ordenes',
//             path: '/dashboard/taller/ordenes'
//           }
//         ]
//       },
//       {
//         title: 'Citas',
//         path: '/dashboard/taller/citas',
//         icon: <EventNote fontSize="small" />
//       },
//       // {
//       //   title: 'Invoices',
//       //   path: '/dashboard/invoices',
//       //   icon: <ReceiptIcon fontSize="small" />,
//       //   children: [
//       //     {
//       //       title: 'List',
//       //       path: '/dashboard/invoices'
//       //     },
//       //     {
//       //       title: 'Details',
//       //       path: '/dashboard/invoices/1'
//       //     }
//       //   ]
//       // }
//     ]
//   },
//   {
//     title: 'Sistema',
//     items: [
//       {
//         title: 'Configuración',
//         path: '/dashboard/sistema',
//         icon: <BriefcaseIcon fontSize="small" />,
//       },
//     ]
//   },
//   // {
//   //   title: 'Platforms',
//   //   items: [
//   //     {
//   //       title: 'Projects',
//   //       path: '/dashboard/projects',
//   //       icon: <BriefcaseIcon fontSize="small" />,
//   //       children: [
//   //         {
//   //           title: 'Browse',
//   //           path: '/dashboard/projects/browse'
//   //         },
//   //         {
//   //           title: 'Details',
//   //           path: '/dashboard/projects/1'
//   //         },
//   //         {
//   //           title: 'Create',
//   //           path: '/dashboard/projects/new'
//   //         }
//   //       ]
//   //     },
//   //     {
//   //       title: 'Social',
//   //       path: '/dashboard/social',
//   //       icon: <ShareIcon fontSize="small" />,
//   //       children: [
//   //         {
//   //           title: 'Profile',
//   //           path: '/dashboard/social/profile'
//   //         },
//   //         {
//   //           title: 'Feed',
//   //           path: '/dashboard/social/feed'
//   //         }
//   //       ]
//   //     }
//   //   ]
//   // },
//   // {
//   //   title: 'Apps',
//   //   items: [
//   //     {
//   //       title: 'Kanban',
//   //       path: '/dashboard/kanban',
//   //       icon: <ClipboardListIcon fontSize="small" />
//   //     },
//   //     {
//   //       title: 'Mail',
//   //       path: '/dashboard/mail',
//   //       icon: <MailIcon fontSize="small" />
//   //     },
//   //     {
//   //       title: 'Chat',
//   //       path: '/dashboard/chat',
//   //       icon: <ChatAltIcon fontSize="small" />
//   //     },
//   //     {
//   //       title: 'Calendar',
//   //       path: '/dashboard/calendar',
//   //       icon: <CalendarIcon fontSize="small" />
//   //     }
//   //   ]
//   // }
// ];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  // const { user } = useAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    sections = [];
    const rank = localStorage.getItem('rank_id');
    if (rank === 'administrator') {
      sections.push({
        title: 'General',
        items: [
          {
            title: 'Resumen',
            path: '/dashboard',
            icon: <ChartSquareBarIcon fontSize="small" />
          },
        ]
      },
      {
        title: 'Administración',
        items: [
          // {
          //   title: 'Ver Admins',
          //   path: '/dashboard/admins/listado',
          //   icon: <UsersIcon fontSize="small" />
          // },
          {
            title: 'Agregar Usuario',
            path: '/dashboard/admins/crear',
            icon: <UsersIcon fontSize="small" />
          },
          {
            title: 'Ver Usuarios',
            path: '/dashboard/admins/listado',
            icon: <EventNote fontSize="small" />
          },
        ]
      },
      {
        title: 'Concesionario',
        items: [
          {
            title: 'Vehículos',
            path: '/dashboard/concesionario/vehiculos',
            icon: <CarRental fontSize="small" />
          },
          {
            title: 'Sucursales',
            path: '/dashboard/concesionario/sucursales',
            icon: <EmojiTransportation fontSize="small" />
          },
          {
            title: 'Citas',
            path: '/dashboard/concesionario/citas',
            icon: <EventNote fontSize="small" />
          },
          {
            title: 'Marcas',
            path: '/dashboard/sistema',
            icon: <Sell fontSize="small" />
          },
        ]
      },
      {
        title: 'Taller',
        items: [
          {
            title: 'Clientes',
            path: '/dashboard/taller',
            icon: <UsersIcon fontSize="small" />,
            children: [
              {
                title: 'Nuevo Cliente',
                path: '/dashboard/taller/clientes/crear'
              },
              {
                title: 'Ver Clientes',
                path: '/dashboard/taller/clientes'
              }
            ]
          },
          {
            title: 'Vehículos',
            path: '/dashboard/taller',
            icon: <CarRepair fontSize="small" />,
            children: [
              {
                title: 'Nuevo Vehículo',
                path: '/dashboard/taller/vehiculos/crear'
              },
              {
                title: 'Ver Vehículos',
                path: '/dashboard/taller/vehiculos'
              }
            ]
          },
          {
            title: 'Ordenes',
            icon: <Assignment fontSize="small" />,
            path: '/dashboard/taller',
            children: [
              {
                title: 'Nueva Orden',
                path: '/dashboard/taller/ordenes/crear'
              },
              {
                title: 'Ver Ordenes',
                path: '/dashboard/taller/ordenes'
              }
            ]
          },
          {
            title: 'Citas',
            path: '/dashboard/taller/citas',
            icon: <EventNote fontSize="small" />
          },
        ]
      }, {
        title: 'Alianzas',
        items: [
          {
            title: 'Resumen',
            path: '/dashboard/alianzas',
            icon: <EventNote fontSize="small" />
          },
        ]
      });
    } else if (rank === 'workshop_agent') {
      sections.push({
        title: 'General',
        items: [
          {
            title: 'Resumen',
            path: '/dashboard',
            icon: <ChartSquareBarIcon fontSize="small" />
          },
        ]
      },
      {
        title: 'Taller',
        items: [
          {
            title: 'Clientes',
            path: '/dashboard/taller',
            icon: <UsersIcon fontSize="small" />,
            children: [
              {
                title: 'Nuevo Cliente',
                path: '/dashboard/taller/clientes/crear'
              },
              {
                title: 'Ver Clientes',
                path: '/dashboard/taller/clientes'
              }
            ]
          },
          {
            title: 'Vehículos',
            path: '/dashboard/taller',
            icon: <CarRepair fontSize="small" />,
            children: [
              {
                title: 'Nuevo Vehículo',
                path: '/dashboard/taller/vehiculos/crear'
              },
              {
                title: 'Ver Vehículos',
                path: '/dashboard/taller/vehiculos'
              }
            ]
          },
          {
            title: 'Ordenes',
            icon: <Assignment fontSize="small" />,
            path: '/dashboard/taller',
            children: [
              {
                title: 'Nueva Orden',
                path: '/dashboard/taller/ordenes/crear'
              },
              {
                title: 'Ver Ordenes',
                path: '/dashboard/taller/ordenes'
              }
            ]
          },
          {
            title: 'Citas',
            path: '/dashboard/taller/citas',
            icon: <EventNote fontSize="small" />
          },
        ]
      });
    } else if (rank === 'dealership_agent') {
      sections.push({
        title: 'General',
        items: [
          {
            title: 'Resumen',
            path: '/dashboard',
            icon: <ChartSquareBarIcon fontSize="small" />
          },
        ]
      },
      {
        title: 'Concesionario',
        items: [
          {
            title: 'Vehiculos',
            path: '/dashboard/concesionario/vehiculos',
            icon: <CarRental fontSize="small" />
          },
          {
            title: 'Sucursales',
            path: '/dashboard/concesionario/sucursales',
            icon: <EmojiTransportation fontSize="small" />
          },
          {
            title: 'Citas',
            path: '/dashboard/concesionario/citas',
            icon: <EventNote fontSize="small" />
          },
          {
            title: 'Marcas',
            path: '/dashboard/sistema',
            icon: <Sell fontSize="small" />
          },
        ]
      });
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        {openMobile && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{ width: '35%' }}
            />
          </Box>
        )}
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <Avatar
              sx={{
                height: 42,
                width: 42,
                backgroundColor: '#E0364C'
              }}
            >
              {getInitials(localStorage.getItem('name'))}
            </Avatar>
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {localStorage.getItem('name')}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                Rango:
                {' '}
                <Link
                  color="primary"
                >
                  {localStorage.getItem('rank_value')}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
        {/* <Divider />
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            Need Help?
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            Check our docs
          </Typography>
          <Button
            color="primary"
            component={RouterLink}
            fullWidth
            sx={{ mt: 2 }}
            to="/docs"
            variant="contained"
          >
            Documentation
          </Button>
        </Box> */}
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
