export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
};

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: 'AIzaSyDISydVWF4-phSiRqeMKkvjxiXFoTIIroA',
  appId: '1:1099500357491:web:5f611f9cf706aa2bb02706',
  authDomain: 'kocars-desarrollo.firebaseapp.com',
  databaseURL: 'G-BD0H704EBQ',
  messagingSenderId: '1099500357491',
  projectId: 'kocars-desarrollo',
  storageBucket: 'kocars-desarrollo.appspot.com'
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID
};
